<template>
  <!-- 审核魅力东疆 -->
  <div v-show="isCheck">
    <a-button @click="openUpload" style="margin: 5px; margin-top: -10px;" type="primary">上传照片至魅力东疆</a-button>
    <!-- 航拍的表格 -->
    <a-table
      v-show="isFly"
      :columns="columns"
      :data-source="data"
      :pagination="pagination"
      :loading="loading"
      :row-key="(record) => record.id"
      @change="changeMessageData"
    >
      <template #mypicture="{ text }">
          <img :src="text" alt="图片"  height="40"/>
        </template>
      <template #name="{ text }">
        <a>{{ text }}</a>
      </template>
      <template #action="{ text }">
        <a-button type="primary" class="delete" @click="showDetails(text)"
          >查看详情</a-button
        >
        <a-button
          type="primary"
          class="delete"
          @click="examineCharmS(text)"
          v-show="text.type == 0 && roleId == 0"
        >
          通过
        </a-button>
        <a-button
          type="danger"
          class="delete"
          @click="examineCharmF(text)"
          v-show="text.type == 0 && roleId == 0"
        >
          不通过
        </a-button>
        <a-button
          type="danger"
          class="delete"
          @click="deleteCharm(text)"
          v-show="roleId == 0"
          >删除</a-button
        >
      </template>
    </a-table>
    <a-modal
      title="详细信息"
      v-model:visible="visible"
      :footer="null"
      width="80%"
    >
      <ul class="input_list">
        <li>
          <span>提交人员： {{ eastmessage.user }} </span>
        </li>
        <li>
          <span>提交时间： {{ eastmessage.time }} </span>
        </li>
        <li>
          <span>内容描述： {{ eastmessage.message }} </span>
        </li>
        <li v-show="showFlyRegion">
          <span>拍摄区域： {{ eastmessage.regionId }} </span>
        </li>
        <li>
          <span>图片展示：</span>
        </li>
        <li>
          <img :src="photoUrl"  style="width: 50%;">
        </li>
      </ul>
    </a-modal>
    <!-- 街拍的表格 -->
    <a-table
      v-show="isStreet"
      :columns="columnsTreet"
      :data-source="dataStreet"
      :pagination="paginationStreet"
      :loading="loading"
      :row-key="(record) => record.id"
      @change="changeStreetData"
    >
      <template #name="{ text }">
        <a>{{ text }}</a>
      </template>
      <template #action="{ text }">
        <a-button type="primary" class="delete" @click="showDetailsStreet(text)"
          >查看详情</a-button
        >
        <a-button
          type="primary"
          class="delete"
          @click="examineStreetCharmS(text)" 
          v-show="text.type == 0 && roleId == 0"
        >
          通过
        </a-button>
        <a-button
          type="danger"
          class="delete"
          @click="examineStreetCharmF(text)"
          v-show="text.type == 0 && roleId == 0"
        >
          不通过
        </a-button>
        <a-button
          type="danger"
          class="delete"
          @click="deleteStreetCharm(text)"
          v-show="roleId == 0"
          >删除</a-button
        >
      </template>
    </a-table>
    <a-modal
      title="详细信息"
      v-model:visible="visibleStreet"
      @ok="onStreetOk"
      @cancel="handleCancelStreet"
      cancelText="取消"
      okText="确定"
    >
      <ul class="input_list">
        <li>
          <span>提交人员： {{ eastmessage.user }} </span>
        </li>
        <li>
          <span>提交时间： {{ eastmessage.time }} </span>
        </li>
        <li>
          <span>内容描述： {{ eastmessage.message }} </span>
        </li>
        <li>
          <span>图片展示：</span>
          <a-button type="primary" @click="showStreetPic(eastmessage)"
            >展示图片</a-button
          >
        </li>
      </ul>
    </a-modal>
    <!-- 航拍图层展示 -->
    <div class="pic-box" ref="pic_ref" style="display: none;z-index:9999999;">
      <div class="delete-box">
        <a-button
          type="danger"
          @click="deletePic"
          shape="circle"
          class="a-button"
          >×</a-button
        >
      </div>
      <div id="feature-pic"></div>
    </div>
    <!-- 街拍图片展示 -->
    <a-modal v-model:visible="visibleStreetPhoto" title="图片详情" :footer="null" width="800" :centered="true">
      <img :src="photoUrl" width="500" >
    </a-modal>
  </div>
  <a-modal
      v-model:visible="uploadVisible"
      title="上传街拍图片"
      @cancel="cancelUpload"
      @ok="uploadCleanerPhotos"
      ok-text="确认"
      cancel-text="取消"
  >
    <input
      type="file"
      ref="fileRef"
      @change="choosePhoto"
      style="display: none"
    />
    <a-button @click="comeToChoose">选择图片</a-button>
    <img src="" ref="photoRef" style="width: 90%; margin: 10px 0;">
    <a-textarea v-model:value="photoText" placeholder="请输入文字介绍" :rows="4" style="width: 90%; margin: 10px 0;"/>
  </a-modal>
</template>

<script>
import { message } from "ant-design-vue";
import { defineComponent } from "vue";
import AILabel from "ailabel";
import OSS from "ali-oss";

// 航拍的表格
const columns = [
  {
    title: "图片",
    dataIndex: "photoUrl",
    key: "photoUrl",
    align: "center",
    slots: { customRender: "mypicture" },
  },
  {
    title: "提交时间",
    dataIndex: "time",
    key: "time",
    align: "center",
  },
  {
    title: "状态",
    dataIndex: "typeName",
    key: "typeName",
    align: "center",
  },
  {
    title: "操作",
    slots: { customRender: "action" },
    key: "action",
    align: "center",
  },
];

//街拍的表格
const columnsTreet = [
  {
    title: "提交人员",
    dataIndex: "userName",
    key: "userName",
    align: "center",
  },
  {
    title: "提交时间",
    dataIndex: "time",
    key: "time",
    align: "center",
  },
  {
    title: "状态",
    dataIndex: "typeName",
    key: "typeName",
    align: "center",
  },
  {
    title: "操作",
    slots: { customRender: "action" },
    key: "action",
    align: "center",
  },
];

const data = [];
const dataStreet = [];

const client = new OSS({
  region: "oss-cn-beijing",
  accessKeyId: "LTAI5tAhC8qbUaiTHPh7dEuA",
  accessKeySecret: "TfALWBoft5i0A2P11Vxd1DGqBqj9bE",
  bucket: "rubbish-demo",
});

export default defineComponent({
  data() {
    return {
      // 浏览魅力东疆展示的是上传时间还是拍摄时间
      showUpdateTime: true,
      //点击查看详情后是否显示拍摄区域
      showFlyRegion: true,
      //显示的街拍图片的地址
      showUrl:'',
      showUrl0: '',
      //显示哪些图片
      isFlyPic:true,
      isStreetPic: false,
      //显示哪一个表格
      isFly:true,
      isStreet: false,
      // 显示哪一个界面
      isCheck: true,
      // 针对管理员或者普通用户进行页面管理
      isManager: true,
      data: data,
      dataStreet:dataStreet,
      columns: columns,
      columnsTreet: columnsTreet,
      loading: false,
      visible: false,
      visibleStreet: false,
      eastmessage: {
        user: "",
        time: "",
        message: "",
        regionName: "",
        photoUrl: "",
        photoId: "",
        modelType: 0,
      },
      pagination: {
        //分页相关
        current: 1,
        pageSize: 8,
        total: 10,
      },
      paginationStreet: {
        //分页相关
        current: 1,
        pageSize: 8,
        total: 10,
      },
      //街拍图片地址
      photoUrl: '',
      visibleStreetPhoto: false,
      // 图层相关
      gFeatureStyle: {},
      gMap: {},
      gImageLayer: {},

      // 浏览相关-----------------------------------------------------
      startTime: "",
      endTime: "",
      createValue: [],
      limit: 100000,
      page: 1,
      index: 0,
      imageList: [],
      indexStreet:0,
      imageStreetList:[],

      gFetureStyle1: {}, //图层样式
      gMap1: {}, //原始图层
      gImageLayer1: {}, //图片图层

      time: "",
      message: "",
      //权限相关
      roleId: this.$storage.get("userinfo").data.role,
      isNextShow: true,
      isPreShow: false,

      //新需求相关
      uploadVisible: false,//上传图片的模态框是否显示
      photoObj: null,//用于保存待上传图片
      photoText: "",//用于文字介绍

      //审核图片类型选择
      checkPhotoType: "a",
      checkShowPhotoType:'c',

      //计时器
      timer: null,
      isPythonShow: 0
    };
  },
  computed: {
    getPicTime() {
      return function() {
        return "拍摄时间：" + this.time;
      };
    },
  },
  methods: {
    //跳转魅力东疆
    gotoTourist() {
      this.isCheck = false;
      this.isFlyPic = true;
      this.isStreetPic = false;
      this.checkShowPhotoType = 'c';
      this.getPicData();
    },
    //魅力东疆中的切换
    changePicType() {
      this.isFlyPic = !this.isFlyPic;
      this.isStreetPic = !this.isStreetPic;
      if (this.isFlyPic === true) {
        this.getPicData()
      } else {
        this.getPicStreetData()
      }
    },
    //页面构建的时候获取列表信息
    getFlyTable() {
      this.loading = true;
      let url = "http://39.100.158.75:8080/showPhoto/getAllShowPhotoByPage";
      this.$axios({
        url: url,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: this.$storage.get("userinfo").data.token,
        },
        params: {
          limit: this.pagination.pageSize,
          page: this.pagination.current,
        },
      }).then((res) => {
        this.loading = false;
        this.data = [];
        if (res.data.data == null) {
          this.pagination.total = 0;
          this.pagination.current = 1;
        } else {
          for (const el of res.data.data.list) {
            if (el.type == 0) {
              var typeName = "等待审核";
            } else if (el.type == 1) {
              typeName = "审核通过";
            } else {
              typeName = "审核未通过";
            }
            // var simpleUrl = el.photo.photoUrl + "?x-oss-process=image/resize,m_fill,h_608,w_1080";
              this.data.push({
              id: el.id,
              type: el.type,
              typeName: typeName,
              userName: el.operator.userName,
              time: el.time,
              regionId: el.regionId,
              message: el.message,
              photoUrl: el.photo.photoUrl,
              photoId: el.photo.id,
              modelType: el.modelType,
              // simpleUrl: simpleUrl,
            });
          }
          this.pagination.total = res.data.data.totalPageCount;
        }
      });
    },
    getStreetTable() {
      this.$axios({
        url: "http://39.100.158.75:8080/showPhoto/getShowPhotoCleanerByPage",
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: this.$storage.get("userinfo").data.token,
        },
        params: {
          limit: this.paginationStreet.pageSize,
          page: this.paginationStreet.current,
        },
      }).then((res) => {
        this.loading = false;
        this.dataStreet = [];
        if (res.data.data == null) {
          this.pagination.total = 0;
          this.pagination.current = 1;
        } else {
          for (const el of res.data.data.list) {
            if (el.type == 0) {
              var typeName = "等待审核";
            } else if (el.type == 1) {
              typeName = "审核通过";
            } else {
              typeName = "审核未通过";
            }
            this.dataStreet.push({
              id: el.id,
              type: el.type,
              typeName: typeName,
              userName: el.operator.userName,
              time: el.updateTime,
              message: el.comment,
              photoUrl: el.photoUrl,
            });
          }
          this.paginationStreet.total = res.data.data.totalPageCount;
          console.log(this.paginationStreet.total);
        }
      })
    },
    changeMessageData(event) {
      this.pagination.current = event.current;
      this.getFlyTable();
    },
    changeStreetData(event) {
      this.paginationStreet.current = event.current;
      this.getStreetTable();
    },
    //航拍系列操作
    //审核图片
      //通过
    examineCharmS(text) {
      if(text.modelType === 0) {
        let url = "http://39.100.158.75:8080/showPhoto/updateOneShowPhoto";
      this.$axios({
        url: url,
        headers: {
          "Content-Type": "application/json",
          token: this.$storage.get("userinfo").data.token,
        },
        method: "POST",
        params: {
          id: text.id,
          type: 1,
        },
      }).then((res) => {
        message.info(res.data.message);
        this.getFlyTable();
      });
      } else {
        let url = "http://39.100.158.75:8080/showPhoto/updateOneShowPhotoCleaner";
      this.$axios({
        url: url,
        headers: {
          "Content-Type": "application/json",
          token: this.$storage.get("userinfo").data.token,
        },
        method: "POST",
        params: {
          id: text.id,
          type: 1,
        },
      }).then((res) => {
        message.info(res.data.message);
        this.getFlyTable();
      });
      }
      
    },
      // 不通过
    examineCharmF(text) {
      if(text.modelType === 0) {
        let url = "http://39.100.158.75:8080/showPhoto/updateOneShowPhoto";
        this.$axios({
        url: url,
        headers: {
          "Content-Type": "application/json",
          token: this.$storage.get("userinfo").data.token,
        },
        method: "POST",
        params: {
          id: text.id,
          type: 2,
        },
      }).then((res) => {
        message.info(res.data.message);
        this.getFlyTable();
      });
      } else {
        let url = "http://39.100.158.75:8080/showPhoto/updateOneShowPhotoCleaner";
      this.$axios({
        url,
        headers: {
          "Content-Type": "application/json",
          token: this.$storage.get("userinfo").data.token,
        },
        method: "POST",
        params: {
          id: text.id,
          type: 2,
        },
      }).then((res) => {
        message.info(res.data.message);
        console.log(res);
        this.getFlyTable();
      });
      }
    },
    onOk() {
      this.visible = false;
    },
    //删除审核信息
    deleteCharm(text) {
      if(text.modelType === 0) {
        let url = "http://39.100.158.75:8080/showPhoto/deleteOneShowPhoto";
        this.$axios({
        url: url,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: this.$storage.get("userinfo").data.token,
        },
        params: {
          id: text.id,
        },
      }).then((res) => {
        message.info(res.data.message);
        this.getFlyTable();
      });
      } else {
        let url = "http://39.100.158.75:8080/showPhoto/deleteOneShowPhotoCleaner";
      this.$axios({
        url: url,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: this.$storage.get("userinfo").data.token,
        },
        params: {
          id: text.id,
        },
      }).then((res) => {
        message.info(res.data.message);
        this.getFlyTable();
      });
      }
    },
    //街拍系列操作
    //审核操作
        //通过
    examineStreetCharmS(text) {
      console.log(text);
      
    },
      // 不通过
    examineStreetCharmF(text) {
      let url = "http://39.100.158.75:8080/showPhoto/updateOneShowPhotoCleaner";
      this.$axios({
        url,
        headers: {
          "Content-Type": "application/json",
          token: this.$storage.get("userinfo").data.token,
        },
        method: "POST",
        params: {
          id: text.id,
          type: 2,
        },
      }).then((res) => {
        message.info(res.data.message);
        console.log(res);
        this.getStreetTable();
      });
    },
    onStreetOk() {
      this.visibleStreet = false;
    },
    //删除审核信息
    deleteStreetCharm(text) {
      console.log(text);
      let url = "http://39.100.158.75:8080/showPhoto/deleteOneShowPhotoCleaner";
      this.$axios({
        url: url,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: this.$storage.get("userinfo").data.token,
        },
        params: {
          id: text.id,
        },
      }).then((res) => {
        message.info(res.data.message);
        this.getStreetTable();
      });
    },
    //点击查看详情
    showDetails(text) {
      console.log(text);
      if(text.modelType === 1) {
        this.showFlyRegion = false;
        this.eastmessage.modelType = 1;
      } else {
        this.showFlyRegion = true;
        this.eastmessage.modelType = 0;
      }
      this.eastmessage.user = text.userName;
      this.eastmessage.time = text.time;
      this.eastmessage.message = text.message;
      this.eastmessage.regionId = text.regionId;
      this.eastmessage.photoUrl = text.photoUrl;
      this.eastmessage.photoId = text.photoId;
      this.photoUrl = text.photoUrl
      this.visible = true;
    },
    showDetailsStreet(text) {
      this.eastmessage.user = text.userName;
      this.eastmessage.time = text.time;
      this.eastmessage.message = text.message;
      this.eastmessage.photoUrl = text.photoUrl;
      this.eastmessage.photoId = text.photoId;
      this.visibleStreet = true;
    }, 
    //模态框的取消按钮
    handleCancel() {
      this.visible = false;
    },
    handleCancelStreet() {
      this.visibleStreet = false;
    },
    // 展示街拍的图片 
    showStreetPic(record) {
      this.visibleStreetPhoto = true;
      this.photoUrl = record.photoUrl;
    },
    //展示图层
    showPicDetails(record) {
      if(record.modelType === 0) {
      this.$refs.pic_ref.style.display = "block";
      this.initpicture(record);
      } else {
        this.showStreetPic(record)
      }
    },
    //关闭图层
    deletePic() {
      this.$refs.pic_ref.style.display = "none";
    },
    // 图层的初始化
    initpicture(record) {
      //常用样式，矩形的边框颜色和宽度
      this.gFeatureStyle = new AILabel.Style({
        strokeColor: "#E22120", //画笔颜色
        lineWeight: 2, //线宽
      });
      //容器对象声明
      this.gMap = new AILabel.Map("feature-pic", {
        zoom: 1080, //初始缩放级别
        cx: 0, //初始中心点坐标x
        cy: 0, //初始中心点坐标y
        zoomMax: 1080, //缩放的最大级别
        zoomMin: 400, //缩放的最小级别
      });
      //图片层实例
      this.gImageLayer = new AILabel.Layer.Image(
        "img1", //实例图层的唯一标志id
        record.photoUrl, //图像的src
        { w: 1080, h: 607.5 }, //图像的原始宽高
        { zIndex: 1 } //config，这里的zIndex决定显示的层级
      );
      //图片层实例的添加
      this.gMap.addLayer(this.gImageLayer);
    },

    // 浏览相关-------------------------------------------------------------------------------------------------
    // 获取街拍图片
    getPicStreetData() {
      let url = "http://39.100.158.75:8080/showPhoto/getPassedShowPhotoCleanerByPage";
      this.$axios({
        url: url,
        headers: {
          "Content-Type": "application/json",
          token: this.$storage.get('userinfo').data.token
        },
        params: {
          limit: this.limit,
          page: this.page,
        },
      }).then(res => {
        this.indexStreet = 0;
        this.page = 1;
        if (res.data.data === null) {
          this.imageStreetList = [];
          this.time = "暂无数据";
          this.message = "暂无数据";
          this.initpicture1();
        } else {
          this.imageStreetList = res.data.data.list;
          this.showUrl = this.imageStreetList[this.indexStreet].photoUrl;
          this.time = this.imageStreetList[this.indexStreet].updateTime;
          this.message = this.imageStreetList[this.indexStreet].comment;
        }
        if (this.imageStreetList.length <= 1) {
          this.isPreShow = false;
          this.isNextShow = false;
        } else {
          this.isPreShow = false;
          this.isNextShow = true;
        }
      });
    },
    // 查看下一张图片
    nextStreet() {
      this.indexStreet++;
      if (this.indexStreet == this.imageStreetList.length - 1) {
        this.isNextShow = false;
      }
      this.showUrl = this.imageStreetList[this.indexStreet].photoUrl
      this.time = this.imageStreetList[this.indexStreet].updateTime;
      this.message = this.imageStreetList[this.indexStreet].comment;
      this.isPreShow = true;
    },
    // 查看上一张图片
    preStreet() {
      this.indexStreet--;
      if (this.indexStreet == 0) {
        this.isPreShow = false;
      } 
      this.showUrl = this.imageStreetList[this.indexStreet].photoUrl
      this.time = this.imageStreetList[this.indexStreet].updateTime;
      this.message = this.imageStreetList[this.indexStreet].comment;
      this.isNextShow = true;
    },
    // 获取航拍图片
    getPicData() {
      let url = "http://39.100.158.75:8080/showPhoto/getAllPassedShowPhotoByPage";
      this.$axios({
        url: url,
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          limit: this.limit,
          page: this.page,
          startTime: this.startTime,
          endTime: this.endTime,
        },
      }).then((res) => {
        this.index = 0;
        this.page = 1;
        if (res.data.data == null) {
          this.imageList = [];
          this.time = "暂无数据";
          this.message = "暂无数据";
        } else {
          this.imageList = res.data.data.list;
          this.showUrl0 = this.imageList[this.index].photo.photoUrl;
          if(res.data.data.list[0].modelType === 0) this.showUpdateTime = false;
          else this.showUpdateTime = true; 
          this.time = this.imageList[this.index].time;
          this.message = this.imageList[this.index].message;
          // this.initpicture1();
        }
        if (this.imageList.length <= 1) {
          this.isPreShow = false;
          this.isNextShow = false;
        } else {
          this.isPreShow = false;
          this.isNextShow = true;
        }
      });
    },
    // 图层初始化
    showBasicLevel() {
      //常用样式，矩形的边框颜色和宽度
      this.gFetureStyle1 = new AILabel.Style({
        strokeColor: "#FF0000", //画笔颜色
        lineWeight: 1, //线宽
      });
      //容器对象声明
      this.gMap1 = new AILabel.Map("human_lable", {
        zoom: 1080, //初始缩放级别
        cx: 0, //初始中心点坐标x
        cy: 0, //初始中心点坐标y
        zoomMax: 1080, //缩放的最大级别
        zoomMin: 400, //缩放的最小级别
      });
    },
    showImageLevel(photoUrl) {
      //图片层实例
      this.gImageLayer1 = new AILabel.Layer.Image(
          "img1", //实例图层的唯一标志id
          photoUrl, //图像的src
          { w: 1080, h: 607.5 }, //图像的原始宽高
          { zIndex: 1 } //config，这里的zIndex决定显示的层级
      );
      //图片层实例的添加
      this.gMap1.addLayer(this.gImageLayer1);
    },
    async initpicture1() {
      this.showBasicLevel();
      this.showImageLevel(this.imageList[this.index].photo.photoUrl);
      //请求图片的识别结果
      let result = await this.$axios({
        url: "http://39.100.158.75:8080/photo/findBlackPhotoByPhotoId",
        method: "GET",
        headers: {
          token: this.$storage.get("userinfo").data.token
        },
        params: {
          photoId: this.imageList[this.index].photo.id
        }
      });
      result = result.data.data;
      this.imageList[this.index].photo.pythonImg = result;
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        if (this.isPythonShow === 0) {
          this.isPythonShow = 1;
          this.showBasicLevel();
          this.showImageLevel(this.imageList[this.index].photo.pythonImg.photoUrl);
        } else {
          this.isPythonShow = 0;
          this.showBasicLevel();
          this.showImageLevel(this.imageList[this.index].photo.photoUrl);
        }
      }, 1 * 1000)
    },
    // 查看下一张图片
    next() {
      this.index++;
      if (this.index == this.imageList.length - 1) {
        this.isNextShow = false;
      }
      if(this.imageList[this.index].modelType === 0) this.showUpdateTime = false;
      else this.showUpdateTime = true;
      // this.initpicture1();
      this.showUrl0 = this.imageList[this.index].photo.photoUrl
      this.time = this.imageList[this.index].time;
      this.message = this.imageList[this.index].message;
      this.isPreShow = true;
    },
    // 查看上一张图片
    pre() {
      this.index--;
      if (this.index == 0) {
        this.isPreShow = false;
      } 
      if(this.imageList[this.index].modelType === 0) this.showUpdateTime = false;
      else this.showUpdateTime = true;
      // this.initpicture1();
      this.showUrl0 = this.imageList[this.index].photo.photoUrl
      this.time = this.imageList[this.index].time;
      this.message = this.imageList[this.index].message;
      this.isNextShow = true;
    },
    comeBack() {
      this.isCheck = true;
    },

    //新需求

    //切换审核的图片类型
    changePhotoType() {
      this.isFly = ! this.isFly;
      this.isStreet = ! this.isStreet;
      this.pagination.current = 1;
      this.paginationStreet.current = 1;
    },

    //打开模态框
    openUpload() {
      this.uploadVisible = true;
    },
    //跳转点击上传图片
    comeToChoose() {
      this.$refs.fileRef.dispatchEvent(new MouseEvent("click"));
    },
    //选择上传的图片
    choosePhoto(event) {
      this.photoObj = event.target.files[0];
      event.target.value = '';
      if (this.photoObj.type === "image/jpeg" || this.photoObj.type === "image/jpeg" || this.photoObj.type === "image/jpeg") {
        let photoUrl = URL.createObjectURL(this.photoObj);//生成上传图片的URL
        this.$refs.photoRef.src = photoUrl;
      } else {
        message.error("文件" + this.photoObj.name + "格式错误，请重新上传！");
      }
    },
    //上传垃圾清理人员的图片至魅力东疆
    async uploadCleanerPhotos() {
      this.uploadVisible = false;
      this.$refs.photoRef.src = "";
      //OSS上传
      let nowTime = new Date().getTime();
      if(this.photoObj == null) this.photoText = '';
      let fileNme = nowTime + "." + this.photoObj.name;//文件名
      let result = await client.put(fileNme, this.photoObj);
      let ossUrl = result.url;
      console.log(ossUrl);
      this.$axios({
        url: "http://39.100.158.75:8080/showPhoto/insertOneShowPhotoCleaner",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: this.$storage.get('userinfo').data.token
        },
        data:
          {
            operatorId: this.$storage.get('userinfo').data.userId,
            photoUrl: ossUrl,
            comment: this.photoText,
            type: '0'
          }
      })
      .then((response) => {
        this.photoText = '';
        console.log(response);
        if(response.data.success == true) {
          this.$message.success(response.data.message)
        }
        else {
          this.$message.error(response.data.message)
        }
      }).catch(() => {
        this.photoText = '';
      })
      
    },
    //取消上传
    cancelUpload() {
      this.uploadVisible = false;
      message.info("您已取消上传");
      this.$refs.photoRef.src = "";
      this.photoText = '';
    }
  },
  mounted() {
    clearInterval(this.timer);
    this.getFlyTable();
    this.getStreetTable();
    message.warning('本页面建议横屏使用！', 4);
  },
  created() {
    if(this.$storage.get('userinfo').data.role != 0) {
      console.log("bushiguanliyuan")
      this.isCheck = false;
      this.isManager = false;
    }
  },
  unmounted() {
    clearInterval(this.timer);
  }
});
</script>

<style lang="scss" scoped>
:deep().ant-table-tbody > tr > td {
  padding: 10px;
}
.delete {
  margin-left: 10px;
}
.input_list {
  padding: 0px;
  list-style: none;
  li {
    line-height: 44px;
    list-style: none;
  }
}
.pic-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 20;
}
.delete-box {
  z-index: 20;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(530px, -325px);
  .a-button {
    width: 35px;
    height: 35px;
    font-size: 20px;
  }
}
#feature-pic {
  position: absolute;
  width: 1080px;
  height: 607.5px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #fff;
}
.head {
  width: 100%;
  height: 50px;
  position: relative;
  .carousel-box {
    width: 800px;
    height: 460px;
    position: absolute;
    top: -36px;
    left: 50%;
    transform: translateX(-50%);
    .carousel-pic {
      // border: 1px solid #ccc;
      position: relative;
      width: 100%;
      #human_lable {
        width: 100%;
        height: 490px;
        position: relative;
        cursor: pointer;
      }
      .carousel-btn {
        font-size: 40px;
        height: 45px;
        width: 45px;
        z-index: 200;
      }
      .right-button {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0, -50%);
      }
      .left-button {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
      }
    }
  }
  .message-box {
    position: absolute;
    padding: 1%;
    left: 50%;
    top: 460px;
    transform: translate(-50%, 0);
    width: 800px;
    border-radius: 2px;
    border: 1px solid #fff;
    background: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18), 0 0 6px rgba(0, 0, 0, 0.13);
  }
}
.btn {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 92%;
}
</style>
